const local = {
  arcregui: {
    url: 'https://ui.dev.globalfiling.com',
  },
  apim: {
    url: 'https://apim.dev.globalfiling.com',
  },
  blob: {
    account: 'dnagfisatrapiineudev',
    pdfaccount: 'dnagfibirtsaeudev',
    testSiteAccount(clientcode) {
      switch (clientcode.toLowerCase()) {
        case 'hsu': return 'dnagfitesteudev';
        case 'hsm': return 'dnagfitestmteudev';
        case 'hsf': return 'dnagfitestfreudev';
        default: return '';
      }
    },
    liveSiteAccount(clientcode) {
      switch (clientcode.toLowerCase()) {
        case 'hsu': return 'dnagfisahsbclifeukeudev';
        case 'hsm': return 'dnagfisahsbclifemteudev';
        case 'hsf': return 'dnagfisahsbclifefreudev';
        default: return '';
      }
    },
  },
  arcapi: {
    url: 'https://apim.dev.globalfiling.com/priipsapi/api/',
  },
  cdata: {
    url: 'https://apim.dev.globalfiling.com/priipsapi/odata/',
  },
  dip: {
    url: 'https://apim.dev.globalfiling.com/postfile/file',
  },
  birt: {
    url: 'https://apim.dev.globalfiling.com/birt/pdf',
  },
  pentaho: {
    url: 'https://pdi.dev.globalfiling.com/kettle/status/',
    wrapperurl: 'https://apim.dev.globalfiling.com/pentahowrapper/DnaPentahoWrapper',
  },
  permissionsApi: {
    url: 'https://apim.dev.globalfiling.com/permission',
  },
  sasTokenApi: {
    url: 'https://apim.dev.globalfiling.com/sastoken',
  },
  powerbi: {
    auditURL: 'https://app.powerbi.com/reportEmbed?reportId=d11e6d45-af9c-4c65-8901-023b069976cc&groupId=4b617ed1-4a30-44c0-bc52-dc51a04ad89d&autoAuth=true&ctid=64ebfaf9-be45-43c2-9e9c-fcb060bf234d',
    otherURL: 'https://app.powerbi.com/reportEmbed?reportId=0bc3ea5c-4a8d-414f-8469-dda2dda73e9e&groupId=4b617ed1-4a30-44c0-bc52-dc51a04ad89d&autoAuth=true&ctid=64ebfaf9-be45-43c2-9e9c-fcb060bf234d',
    clientonboardingURL: 'https://app.powerbi.com/reportEmbed?reportId=6e9f0142-fcb9-47d7-9bf0-6677c620c926&groupId=4b617ed1-4a30-44c0-bc52-dc51a04ad89d&autoAuth=true&ctid=64ebfaf9-be45-43c2-9e9c-fcb060bf234d',
  },
  routes: {
    adminRoutes: ['/grid', '/ept', '/emt', '/dcpt', '/dashboard', '/manageclients', '/shareclass', '/fund', '/insurancegrid', '/powerbi', '/dropfile', '/import', '/pentaho', '/audit', '/reportingperiod', '/overview', '/merge', '/raw', '/calculations', '/spview', '/clientonboarding', '/approvalcalclog', '/tcview', '/useradmin', '/user', '/pentahojoberrors', '/output', '/insspview', '/insurancedashboard', '/pdflandingpage', '/transdashboard', '/transoutputlandingpage'],
    userRoutes: ['/grid', '/ept', '/emt', '/dcpt', '/dashboard', '/manageclients', '/shareclass', '/fund', '/insurancegrid', '/powerbi', '/dropfile', '/import', '/audit', '/reportingperiod', '/overview', '/merge', '/raw', '/calculations', '/spview', '/clientonboarding', '/approvalcalclog', '/tcview', '/pentahojoberrors', '/output', '/insspview', '/insurancedashboard', '/pdflandingpage', '/transdashboard', '/transoutputlandingpage'],
    readonlyRoutes: ['/grid', '/ept', '/emt', '/dcpt', '/dashboard', '/manageclients', '/shareclass', '/fund', '/insurancegrid', '/powerbi', '/dropfile', '/audit', '/overview', '/calculations', '/spview', '/clientonboarding', '/approvalcalclog', '/tcview', '/pentahojoberrors', '/output', '/insspview', '/insurancedashboard', '/transdashboard', '/transoutputlandingpage'],
  },
  sessionStorage: {
    encKey: 'super secret key',
  },
  auth0: {
    audience: 'https://dfin.arcreg.com',
    callbackUrl: 'https://localhost:3000/callback',
    clientId: 'mgCiCIHB2LxkqWdzNyhBNMeGUxfvv2Da',
    domain: 'login-ci.dfinsolutions.com',
    idleTimeout: 90,
    logoutRedirectUrl: 'https://localhost:3000/',
    refreshInterval: 60,
    responseType: 'token id_token code',
  },
  site: {
    testSiteLink(clientcode) {
      switch (clientcode.toLowerCase()) {
        case 'hsu': return 'https://hsbclifeuk.test.dev.globalfiling.com/';
        case 'hsf': return 'https://hsbclifefr.test.dev.globalfiling.com/';
        case 'hsm': return 'https://hsbclifemt.test.dev.globalfiling.com/';
        default: return '';
      }
    },
    liveSiteLink(clientcode) {
      switch (clientcode.toLowerCase()) {
        case 'hsu': return 'https://hsbclifeuk.dev.globalfiling.com/';
        case 'hsf': return 'https://hsbclifefr.dev.globalfiling.com/';
        case 'hsm': return 'https://hsbclifemt.dev.globalfiling.com/';
        default: return '';
      }
    },
  },
};

const dev = {
  arcregui: {
    url: 'https://ui.dev.globalfiling.com',
  },
  apim: {
    url: 'https://apim.dev.globalfiling.com',
  },
  blob: {
    account: 'dnagfisatrapiineudev',
    pdfaccount: 'dnagfibirtsaeudev',
    testSiteAccount(clientcode) {
      switch (clientcode.toLowerCase()) {
        case 'hsu': return 'dnagfitesteudev';
        case 'hsm': return 'dnagfitestmteudev';
        case 'hsf': return 'dnagfitestfreudev';
        default: return '';
      }
    },
    liveSiteAccount(clientcode) {
      switch (clientcode.toLowerCase()) {
        case 'hsu': return 'dnagfisahsbclifeukeudev';
        case 'hsm': return 'dnagfisahsbclifemteudev';
        case 'hsf': return 'dnagfisahsbclifefreudev';
        default: return '';
      }
    },
  },
  arcapi: {
    url: 'https://apim.dev.globalfiling.com/priipsapi/api/',
  },
  cdata: {
    url: 'https://apim.dev.globalfiling.com/priipsapi/odata/',
  },
  dip: {
    url: 'https://apim.dev.globalfiling.com/postfile/file',
  },
  birt: {
    url: 'https://apim.dev.globalfiling.com/birt/pdf',
  },
  pentaho: {
    url: 'https://pdi.dev.globalfiling.com/kettle/status/',
    wrapperurl: 'https://apim.dev.globalfiling.com/pentahowrapper/DnaPentahoWrapper',
  },
  permissionsApi: {
    url: 'https://apim.dev.globalfiling.com/permission',
  },
  sasTokenApi: {
    url: 'https://apim.dev.globalfiling.com/sastoken',
  },
  powerbi: {
    auditURL: 'https://app.powerbi.com/reportEmbed?reportId=d11e6d45-af9c-4c65-8901-023b069976cc&groupId=4b617ed1-4a30-44c0-bc52-dc51a04ad89d&autoAuth=true&ctid=64ebfaf9-be45-43c2-9e9c-fcb060bf234d',
    otherURL: 'https://app.powerbi.com/reportEmbed?reportId=0bc3ea5c-4a8d-414f-8469-dda2dda73e9e&groupId=4b617ed1-4a30-44c0-bc52-dc51a04ad89d&autoAuth=true&ctid=64ebfaf9-be45-43c2-9e9c-fcb060bf234d',
    clientonboardingURL: 'https://app.powerbi.com/reportEmbed?reportId=6e9f0142-fcb9-47d7-9bf0-6677c620c926&groupId=4b617ed1-4a30-44c0-bc52-dc51a04ad89d&autoAuth=true&ctid=64ebfaf9-be45-43c2-9e9c-fcb060bf234d',
  },
  routes: {
    adminRoutes: ['/grid', '/ept', '/emt', '/dcpt', '/dashboard', '/manageclients', '/shareclass', '/fund', '/insurancegrid', '/powerbi', '/dropfile', '/import', '/pentaho', '/audit', '/reportingperiod', '/overview', '/merge', '/raw', '/calculations', '/spview', '/clientonboarding', '/approvalcalclog', '/tcview', '/useradmin', '/user', '/pentahojoberrors', '/output', '/insspview', '/insurancedashboard', '/pdflandingpage', '/transdashboard', '/transoutputlandingpage'],
    userRoutes: ['/grid', '/ept', '/emt', '/dcpt', '/dashboard', '/manageclients', '/shareclass', '/fund', '/insurancegrid', '/powerbi', '/dropfile', '/import', '/audit', '/reportingperiod', '/overview', '/merge', '/raw', '/calculations', '/spview', '/clientonboarding', '/approvalcalclog', '/tcview', '/pentahojoberrors', '/output', '/insspview', '/insurancedashboard', '/pdflandingpage', '/transdashboard', '/transoutputlandingpage'],
    readonlyRoutes: ['/grid', '/ept', '/emt', '/dcpt', '/dashboard', '/manageclients', '/shareclass', '/fund', '/insurancegrid', '/powerbi', '/dropfile', '/audit', '/overview', '/calculations', '/spview', '/clientonboarding', '/approvalcalclog', '/tcview', '/pentahojoberrors', '/output', '/insspview', '/insurancedashboard', '/transdashboard', '/transoutputlandingpage'],
  },
  sessionStorage: {
    encKey: 'super secret key',
  },
  auth0: {
    audience: 'https://dfin.arcreg.com',
    callbackUrl: 'https://dev.globalfiling.com/callback',
    clientId: 'mgCiCIHB2LxkqWdzNyhBNMeGUxfvv2Da',
    domain: 'login-ci.dfinsolutions.com',
    idleTimeout: 90,
    logoutRedirectUrl: 'https://dev.globalfiling.com/',
    refreshInterval: 60,
    responseType: 'token id_token code',
  },
  site: {
    testSiteLink(clientcode) {
      switch (clientcode.toLowerCase()) {
        case 'hsu': return 'https://hsbclifeuk.test.dev.globalfiling.com/';
        case 'hsf': return 'https://hsbclifefr.test.dev.globalfiling.com/';
        case 'hsm': return 'https://hsbclifemt.test.dev.globalfiling.com/';
        default: return '';
      }
    },
    liveSiteLink(clientcode) {
      switch (clientcode.toLowerCase()) {
        case 'hsu': return 'https://hsbclifeuk.dev.globalfiling.com/';
        case 'hsf': return 'https://hsbclifefr.dev.globalfiling.com/';
        case 'hsm': return 'https://hsbclifemt.dev.globalfiling.com/';
        default: return '';
      }
    },
  },
};

const uat = {
  arcregui: {
    url: 'https://ui.uat.globalfiling.com',
  },
  apim: {
    url: 'https://apim.uat.globalfiling.com',
  },
  birt: {
    url: 'https://apim.uat.globalfiling.com/birt/pdf',
  },
  blob: {
    account: 'dnagfisatrapiineuuat',
    pdfaccount: 'dnagfibirtsaeuuat',
    testSiteAccount(clientcode) {
      switch (clientcode.toLowerCase()) {
        case 'hsu': return 'dnagfitesteuuat';
        case 'hsm': return 'dnagfitestmteuuat';
        case 'hsf': return 'dnagfitestfreuuat';
        default: return '';
      }
    },
    liveSiteAccount(clientcode) {
      switch (clientcode.toLowerCase()) {
        case 'hsu': return 'dnagfisahsbclifeukeuuat';
        case 'hsm': return 'dnagfisahsbclifemteuuat';
        case 'hsf': return 'dnagfisahsbclifefreuuat';
        default: return '';
      }
    },
  },
  arcapi: {
    url: 'https://apim.uat.globalfiling.com/priipsapi/api/',
  },
  cdata: {
    url: 'https://apim.uat.globalfiling.com/priipsapi/odata/',
  },
  dip: {
    url: 'https://apim.uat.globalfiling.com/postfile/file',
  },
  pentaho: {
    url: 'https://pdi.uat.globalfiling.com/kettle/status/',
    wrapperurl: 'https://apim.uat.globalfiling.com/pentahowrapper/DnaPentahoWrapper',
  },
  permissionsApi: {
    url: 'https://apim.uat.globalfiling.com/permission',
  },
  sasTokenApi: {
    url: 'https://apim.uat.globalfiling.com/sastoken',
  },
  powerbi: {
    auditURL: 'https://app.powerbi.com/reportEmbed?reportId=d11e6d45-af9c-4c65-8901-023b069976cc&groupId=4b617ed1-4a30-44c0-bc52-dc51a04ad89d&autoAuth=true&ctid=64ebfaf9-be45-43c2-9e9c-fcb060bf234d',
    otherURL: 'https://app.powerbi.com/reportEmbed?reportId=0bc3ea5c-4a8d-414f-8469-dda2dda73e9e&groupId=4b617ed1-4a30-44c0-bc52-dc51a04ad89d&autoAuth=true&ctid=64ebfaf9-be45-43c2-9e9c-fcb060bf234d',
    clientonboardingURL: 'https://app.powerbi.com/reportEmbed?reportId=6877fcc7-a960-4479-9c65-17c53427edb9&groupId=4b617ed1-4a30-44c0-bc52-dc51a04ad89d&autoAuth=true&ctid=64ebfaf9-be45-43c2-9e9c-fcb060bf234d',
  },
  routes: {
    adminRoutes: ['/grid', '/ept', '/emt', '/dcpt', '/dashboard', '/manageclients', '/shareclass', '/fund', '/insurancegrid', '/powerbi', '/dropfile', '/import', '/pentaho', '/audit', '/reportingperiod', '/overview', '/merge', '/raw', '/calculations', '/spview', '/clientonboarding', '/approvalcalclog', '/tcview', '/useradmin', '/user', '/pentahojoberrors', '/output', '/insspview', '/insurancedashboard', '/pdflandingpage', '/transdashboard', '/transoutputlandingpage'],
    userRoutes: ['/grid', '/ept', '/emt', '/dcpt', '/dashboard', '/manageclients', '/shareclass', '/fund', '/insurancegrid', '/powerbi', '/dropfile', '/import', '/audit', '/reportingperiod', '/overview', '/merge', '/raw', '/calculations', '/spview', '/clientonboarding', '/approvalcalclog', '/tcview', '/pentahojoberrors', '/output', '/insspview', '/insurancedashboard', '/pdflandingpage', '/transdashboard', '/transoutputlandingpage'],
    readonlyRoutes: ['/grid', '/ept', '/emt', '/dcpt', '/dashboard', '/manageclients', '/shareclass', '/fund', '/insurancegrid', '/powerbi', '/dropfile', '/audit', '/overview', '/calculations', '/spview', '/clientonboarding', '/approvalcalclog', '/tcview', '/pentahojoberrors', '/output', '/insspview', '/insurancedashboard', '/transdashboard', '/transoutputlandingpage'],
  },
  sessionStorage: {
    encKey: 'super secret key',
  },
  auth0: {
    audience: 'https://dfin.arcreg.com',
    callbackUrl: 'https://uat.globalfiling.com/callback',
    clientId: 'SohgVRhTY3iZqVc3kKsbP2zn9cKF9Wq2',
    domain: 'login-stage.dfinsolutions.com',
    idleTimeout: 90,
    logoutRedirectUrl: 'https://uat.globalfiling.com/',
    refreshInterval: 180,
    responseType: 'token id_token code',
  },
  site: {
    testSiteLink(clientcode) {
      switch (clientcode.toLowerCase()) {
        case 'hsu': return 'https://hsbclifeuk.test.uat.globalfiling.com/';
        case 'hsf': return 'https://hsbclifefr.test.uat.globalfiling.com/';
        case 'hsm': return 'https://hsbclifemt.test.uat.globalfiling.com/';
        default: return '';
      }
    },
    liveSiteLink(clientcode) {
      switch (clientcode.toLowerCase()) {
        case 'hsu': return 'https://hsbclifeuk.uat.globalfiling.com/';
        case 'hsf': return 'https://hsbclifefr.uat.globalfiling.com/';
        case 'hsm': return 'https://hsbclifemt.uat.globalfiling.com/';
        default: return '';
      }
    },
  },
};

const prd = {
  arcregui: {
    url: 'https://ui.globalfiling.com',
  },
  apim: {
    url: 'https://apim.globalfiling.com',
  },
  blob: {
    account: 'dnagfisatrapiineuprd',
    pdfaccount: 'dnagfibirtsaeuprd',
    testSiteAccount(clientcode) {
      switch (clientcode.toLowerCase()) {
        case 'hsu': return 'dnagfitesteuprd';
        case 'hsm': return 'dnagfitestmteuprd';
        case 'hsf': return 'dnagfitestfreuprd';
        default: return '';
      }
    },
    liveSiteAccount(clientcode) {
      switch (clientcode.toLowerCase()) {
        case 'hsu': return 'dnagfisahsbclifeukeuprd';
        case 'hsm': return 'dnagfisahsbclifemteuprd';
        case 'hsf': return 'dnagfisahsbclifefreuprd';
        default: return '';
      }
    },
  },
  arcapi: {
    url: 'https://apim.globalfiling.com/priipsapi/api/',
  },
  cdata: {
    url: 'https://apim.globalfiling.com/priipsapi/odata/',
  },
  dip: {
    url: 'https://apim.globalfiling.com/postfile/file',
  },
  birt: {
    url: 'https://apim.globalfiling.com/birt/pdf',
  },
  pentaho: {
    url: 'https://pdi.globalfiling.com/kettle/status/',
    wrapperurl: 'https://apim.globalfiling.com/pentahowrapper/DnaPentahoWrapper',
  },
  permissionsApi: {
    url: 'https://apim.globalfiling.com/permission',
  },
  sasTokenApi: {
    url: 'https://apim.globalfiling.com/sastoken',
  },
  powerbi: {
    auditURL: 'https://app.powerbi.com/reportEmbed?reportId=d11e6d45-af9c-4c65-8901-023b069976cc&groupId=4b617ed1-4a30-44c0-bc52-dc51a04ad89d&autoAuth=true&ctid=64ebfaf9-be45-43c2-9e9c-fcb060bf234d',
    otherURL: 'https://app.powerbi.com/reportEmbed?reportId=0bc3ea5c-4a8d-414f-8469-dda2dda73e9e&groupId=4b617ed1-4a30-44c0-bc52-dc51a04ad89d&autoAuth=true&ctid=64ebfaf9-be45-43c2-9e9c-fcb060bf234d',
    clientonboardingURL: 'https://app.powerbi.com/reportEmbed?reportId=6877fcc7-a960-4479-9c65-17c53427edb9&groupId=4b617ed1-4a30-44c0-bc52-dc51a04ad89d&autoAuth=true&ctid=64ebfaf9-be45-43c2-9e9c-fcb060bf234d',
  },
  routes: {
    adminRoutes: ['/grid', '/ept', '/emt', '/dcpt', '/dashboard', '/manageclients', '/shareclass', '/fund', '/insurancegrid', '/powerbi', '/dropfile', '/import', '/pentaho', '/audit', '/reportingperiod', '/overview', '/merge', '/raw', '/calculations', '/spview', '/clientonboarding', '/approvalcalclog', '/tcview', '/useradmin', '/user', '/pentahojoberrors', '/output', '/insspview', '/insurancedashboard', '/pdflandingpage', '/transdashboard', '/transoutputlandingpage'],
    userRoutes: ['/grid', '/ept', '/emt', '/dcpt', '/dashboard', '/manageclients', '/shareclass', '/fund', '/insurancegrid', '/powerbi', '/dropfile', '/import', '/audit', '/reportingperiod', '/overview', '/merge', '/raw', '/calculations', '/spview', '/clientonboarding', '/approvalcalclog', '/tcview', '/pentahojoberrors', '/output', '/insspview', '/insurancedashboard', '/pdflandingpage', '/transdashboard', '/transoutputlandingpage'],
    readonlyRoutes: ['/grid', '/ept', '/emt', '/dcpt', '/dashboard', '/manageclients', '/shareclass', '/fund', '/insurancegrid', '/powerbi', '/dropfile', '/audit', '/overview', '/calculations', '/spview', '/clientonboarding', '/approvalcalclog', '/tcview', '/pentahojoberrors', '/output', '/insspview', '/insurancedashboard', '/transdashboard', '/transoutputlandingpage'],
  },
  sessionStorage: {
    encKey: 'super secret key',
  },
  auth0: {
    audience: 'https://dfin.arcreg.com',
    callbackUrl: 'https://globalfiling.com/callback',
    clientId: 'FL25iTZ4UOd7B8YJhysdDCs046xGteko',
    domain: 'login.dfinsolutions.com',
    idleTimeout: 90,
    logoutRedirectUrl: 'https://globalfiling.com/',
    refreshInterval: 60,
    responseType: 'token id_token code',
  },
  site: {
    testSiteLink(clientcode) {
      switch (clientcode.toLowerCase()) {
        case 'hsu': return 'https://hsbclifeuk.test.globalfiling.com/';
        case 'hsf': return 'https://hsbclifefr.test.globalfiling.com/';
        case 'hsm': return 'https://hsbclifemt.test.globalfiling.com/';
        default: return '';
      }
    },
    liveSiteLink(clientcode) {
      switch (clientcode.toLowerCase()) {
        case 'hsu': return 'https://hsbclifeuk.globalfiling.com/';
        case 'hsf': return 'https://hsbclifefr.globalfiling.com/';
        case 'hsm': return 'https://hsbclifemt.globalfiling.com/';
        default: return '';
      }
    },
  },
};

const qa = {
  arcregui: {
    url: 'https://ui.qa.globalfiling.com',
  },
  apim: {
    url: 'https://apim.qa.globalfiling.com',
  },
  blob: {
    account: 'dnagfisatrapiineuqa',
    pdfaccount: 'dnagfibirtsaeuqa',
    testSiteAccount(clientcode) {
      switch (clientcode.toLowerCase()) {
        case 'hsu': return 'dnagfitesteuqa';
        case 'hsm': return 'dnagfitestmteuqa';
        case 'hsf': return 'dnagfitestfreuqa';
        default: return '';
      }
    },
    liveSiteAccount(clientcode) {
      switch (clientcode.toLowerCase()) {
        case 'hsu': return 'dnagfisahsbclifeukeuqa';
        case 'hsm': return 'dnagfisahsbclifemteuqa';
        case 'hsf': return 'dnagfisahsbclifefreuqa';
        default: return '';
      }
    },
  },
  arcapi: {
    url: 'https://apim.qa.globalfiling.com/priipsapi/api/',
  },
  cdata: {
    url: 'https://apim.qa.globalfiling.com/priipsapi/odata/',
  },
  dip: {
    url: 'https://apim.qa.globalfiling.com/postfile/file',
  },
  birt: {
    url: 'https://apim.qa.globalfiling.com/birt/pdf',
  },
  pentaho: {
    url: 'https://pdi.qa.globalfiling.com/kettle/status/',
    wrapperurl: 'https://apim.qa.globalfiling.com/pentahowrapper/DnaPentahoWrapper',
  },
  permissionsApi: {
    url: 'https://apim.qa.globalfiling.com/permission',
  },
  sasTokenApi: {
    url: 'https://apim.qa.globalfiling.com/sastoken',
  },
  powerbi: {
    auditURL: 'https://app.powerbi.com/reportEmbed?reportId=d11e6d45-af9c-4c65-8901-023b069976cc&groupId=4b617ed1-4a30-44c0-bc52-dc51a04ad89d&autoAuth=true&ctid=64ebfaf9-be45-43c2-9e9c-fcb060bf234d',
    otherURL: 'https://app.powerbi.com/reportEmbed?reportId=0bc3ea5c-4a8d-414f-8469-dda2dda73e9e&groupId=4b617ed1-4a30-44c0-bc52-dc51a04ad89d&autoAuth=true&ctid=64ebfaf9-be45-43c2-9e9c-fcb060bf234d',
    clientonboardingURL: 'https://app.powerbi.com/reportEmbed?reportId=6e9f0142-fcb9-47d7-9bf0-6677c620c926&groupId=4b617ed1-4a30-44c0-bc52-dc51a04ad89d&autoAuth=true&ctid=64ebfaf9-be45-43c2-9e9c-fcb060bf234d',
  },
  routes: {
    adminRoutes: ['/grid', '/ept', '/emt', '/dcpt', '/dashboard', '/manageclients', '/shareclass', '/fund', '/insurancegrid', '/powerbi', '/dropfile', '/import', '/pentaho', '/audit', '/reportingperiod', '/overview', '/merge', '/raw', '/calculations', '/spview', '/clientonboarding', '/approvalcalclog', '/tcview', '/useradmin', '/user', '/pentahojoberrors', '/output', '/insspview', '/insurancedashboard', '/pdflandingpage', '/transdashboard', '/transoutputlandingpage'],
    userRoutes: ['/grid', '/ept', '/emt', '/dcpt', '/dashboard', '/manageclients', '/shareclass', '/fund', '/insurancegrid', '/powerbi', '/dropfile', '/import', '/audit', '/reportingperiod', '/overview', '/merge', '/raw', '/calculations', '/spview', '/clientonboarding', '/approvalcalclog', '/tcview', '/pentahojoberrors', '/output', '/insspview', '/insurancedashboard', '/pdflandingpage', '/transdashboard', '/transoutputlandingpage'],
    readonlyRoutes: ['/grid', '/ept', '/emt', '/dcpt', '/dashboard', '/manageclients', '/shareclass', '/fund', '/insurancegrid', '/powerbi', '/dropfile', '/audit', '/overview', '/calculations', '/spview', '/clientonboarding', '/approvalcalclog', '/tcview', '/pentahojoberrors', '/output', '/insspview', '/insurancedashboard', '/transdashboard', '/transoutputlandingpage'],
  },
  sessionStorage: {
    encKey: 'super secret key',
  },
  auth0: {
    audience: 'https://dfin.arcreg.com',
    callbackUrl: 'https://qa.globalfiling.com/callback',
    clientId: 'xetsRnuiiysRQtgHPeCSzq0gNELgHuqr',
    domain: 'login-ci.dfinsolutions.com',
    idleTimeout: 90,
    logoutRedirectUrl: 'https://qa.globalfiling.com/',
    refreshInterval: 60,
    responseType: 'token id_token code',
  },
  site: {
    testSiteLink(clientcode) {
      switch (clientcode.toLowerCase()) {
        case 'hsu': return 'https://hsbclifeuk.test.qa.globalfiling.com/';
        case 'hsf': return 'https://hsbclifefr.test.qa.globalfiling.com/';
        case 'hsm': return 'https://hsbclifemt.test.qa.globalfiling.com/';
        default: return '';
      }
    },
    liveSiteLink(clientcode) {
      switch (clientcode.toLowerCase()) {
        case 'hsu': return 'https://hsbclifeuk.qa.globalfiling.com/';
        case 'hsf': return 'https://hsbclifefr.qa.globalfiling.com/';
        case 'hsm': return 'https://hsbclifemt.qa.globalfiling.com/';
        default: return '';
      }
    },
  },
};

const config = window.location.host.includes('localhost') ? local 
: window.location.host.includes('dev') ? dev
  : window.location.host.includes('qa.globalfiling.com') ? qa
    : window.location.host.match('uat.globalfiling.com') ? uat
        : window.location.host.match('globalfiling.com') && prd;

export default { ...config };
